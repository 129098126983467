<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		rank?: number;
		iconSize?: number;
		points?: number;
		entries?: number;
		coins?: number;
		userName?: string;
		userLoc?: string;
		userActive?: boolean;
		prizeWeight?: "bold" | "normal";
		withStroke?: boolean;
		prizePlace?: boolean;
		useInModal?: boolean;
		isFlag?: boolean;
	}>(),
	{ iconSize: 20, rank: 0, useInModal: false, withStroke: false }
);

const { t } = useT();

const iconSize = computed(() => `${props.iconSize}px`);
</script>

<template>
	<div class="race-row" :class="{ active: props.userActive && useInModal }">
		<div class="race-wrapper" :class="{ active: userActive, withStroke: props.withStroke }">
			<div class="cell start">
				<div class="rank">
					<div class="rank-icon-wrap">
						<NuxtIcon
							v-if="props.prizePlace"
							:name="`20/${isFlag ? 'flag' : 'tournaments'}`"
							class="rank-icon"
							filled
						/>
					</div>
					<AText class="rank-index" :modifiers="['bold']">
						{{ props.rank }}
					</AText>
				</div>
				<div v-if="props.userName || props.userLoc" class="user">
					<AText class="user-name" :modifiers="['medium', 'ellipsis']">
						{{ userActive ? t("It`s Me") : props.userName }}
					</AText>
					<AText class="color-gray-200" :size="10" :modifiers="['ellipsis']">{{ props.userLoc }}</AText>
				</div>
			</div>
			<div v-if="props.coins || props.entries" class="cell middle" :class="{ right: !props.points }">
				<div class="prizes">
					<MPrizeFund v-if="props.coins" icon="12/coins" variant="coins" :iconSize="20">
						<AText
							variant="toledo"
							:stroke="withStroke ? '3px var(--congo)' : ''"
							:class="withStroke ? 'text-chihuahua' : 'color-neutral'"
							:modifiers="[withStroke ? 'bold' : 'semibold']"
						>
							{{ numberFormat(props.coins) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund v-if="props.entries" icon="12/secret-coins" variant="entries" :iconSize="20">
						<AText
							variant="toledo"
							:stroke="withStroke ? '3px var(--campeche)' : ''"
							:class="withStroke ? 'text-coimbatore' : 'color-neutral'"
							:modifiers="[withStroke ? 'bold' : 'semibold']"
						>
							{{ numberFormat(props.entries) }}
						</AText>
					</MPrizeFund>
				</div>
			</div>
			<div v-else class="cell middle" />
			<div v-if="props.points" class="cell right">
				<AText>{{ numberFormat(props.points || 0) }}</AText>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.race-row.active {
	position: sticky;
	z-index: 1;
	bottom: 0;
}
:deep(.prize) {
	gap: 4px;

	span svg {
		margin-bottom: 3px;
	}
}
.race-wrapper {
	padding: 8px 16px;
	border-radius: 10px;
	background: var(--gray-800);
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	box-shadow: 0px 2px 2px 0px var(--cagli);
	min-height: 52px;

	&.active {
		background: var(--secondary-800);
		& .rank-icon:deep(svg path) {
			fill: var(--coro);
		}

		& .start *,
		& .right * {
			color: var(--coro);
		}
	}
	&:not(.withStroke).active {
		& .middle * {
			color: var(--coro);
		}
	}
}
.cell {
	display: flex;
	align-items: center;
	gap: 12px;
	flex: 1 1 33.3333%;

	&.start {
		align-items: flex-start;
	}
	&.middle {
		justify-content: flex-start;
	}
	&.right {
		justify-content: flex-start;
		flex: 1 1 5%;
	}
}
.rank {
	display: flex;
	align-items: center;
	gap: 4px;
}
.rank-icon-wrap {
	height: v-bind(iconSize);
	width: v-bind(iconSize);
}
.prizes {
	display: flex;
	flex-direction: column;
}
.user {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		width: calc(100% - 35px);
	}
}
.rank-icon {
	display: flex;
	font-size: v-bind(iconSize);

	&:deep(svg) {
		margin: 0;
		path {
			fill: var(--neutral);
		}
	}
}
.user-name {
	line-height: 150%;
}
.rank-index {
	line-height: 150%;
}
</style>
